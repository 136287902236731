<template>
  <header>
    <div class="nav-wrapper">
      <div class="logo">
        <img src="imgs/tab-logo.svg" />
      </div>
      <nav>
        <ul>
          <li class="index">
            <a :class="active == 'index' && 'active'" href='/'>首页</a>
          </li>
          <li class="appindex">
            <a :class="active == 'appindex'&& 'active'" href="appIndex.html">亲情守护APP</a>
          </li>
          <li class="antiindex">
            <a :class="active == 'antiindex'&& 'active'" href="antiIndex.html">防沉迷研究所</a>
          </li>
          <li class="newsindex">
            <a :class="active == 'newsindex'&& 'active'" href="newsIndex.html">品牌资讯</a>
          </li>
          <li class="serviceindex">
            <a :class="active == 'serviceindex' && 'active'" href="serviceIndex.html">服务支持</a>
          </li>
          <li class="aboutindex">
            <a :class="active == 'aboutindex'&& 'active'" href="aboutIndex.html">关于我们</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      value: ''
    }
  },
  // mounted () {
  //   console.log(this.active)
  // },
}
</script>
<style scoped>
/* 导航 */
header {
  width: 100%;
}
header .nav-wrapper{
  height: 64px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header.sticky{
  background: white;
  z-index: 100;
  box-shadow: 0 5px 5px rgb(0 0 0 / 7%);
  color: #3d4451;
  position: fixed;
  width: 100%;
}
.logo {
  width: 282px;
  height: 30px;
}

.logo > img {
  width: 282px;
  height: 30px;
  display: inline-block;
}
nav ul{
  display: flex;
}
nav ul li a{
  font-family: OPPOSans-M, OPPOSans;
  font-weight: normal;
  display: inline-block;
  height: 64px;
  cursor: pointer;
  width: 114px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  color: #666666;
  line-height: 64px;
}
nav ul li.index{
  margin-right: 10px;
}
nav ul li.appindex{
  margin-right: 26px;
}
nav ul li.antiindex{
  margin-right: 17px;
}

nav ul li a:hover{
  font-family: OPPOSans-B, OPPOSans;
  color: #191919;
}
nav ul li a.active{
  font-family: OPPOSans-B, OPPOSans;
  color: #191919;
  background-image: url(../assets/nav-active.png);
  background-repeat: no-repeat;
  background-position: center 61px;
}
</style>