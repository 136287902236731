import LoginDialog from './LoginDialog.vue'

const LoginDialogConstructor = Vue.extend(LoginDialog)

const loginDialog = new LoginDialogConstructor({
  el: document.createElement('div')
})

document.body.appendChild(loginDialog.$el)

loginDialog.$on('login-success', () => {
  if (loginDialog.callback) {
    loginDialog.callback()
  } else {
    location.reload()
  }
})

function login (opts) {
  let options = opts || {}
  options.title && (loginDialog.title = opts.title)
  loginDialog.callback = options.callback
  loginDialog.visible = true
}

export default {
  install (Vue) {
    Vue.login = login
    Vue.prototype.$login = login
  }
}