<template>
  <section class="qrcode">
    <div class="qrcode-wrapper">
      <img src="imgs/qrcode-mall.png" />
      <div class="qrcode-content">360OS奇少年手机官方商城</div>
    </div>
    <div class="qrcode-wrapper">
      <img src="imgs/qrcode-public.png" />
      <div class="qrcode-content">360OS奇少年手机公众号</div>
    </div>
    <!-- <div class="qrcode-wrapper">
      <img src="imgs/qrcode-pre-sale.png" />
      <div class="qrcode-content">售前客服</div>
    </div> -->
    <div class="qrcode-wrapper">
      <img src="imgs/qrcode-after-sale.png" />
      <div class="qrcode-content">售后客服</div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>
<style scoped>
/* 二维码 */
.qrcode{
  /* min-width: 1300px; */
  background: url(../assets/qrcode.png) no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9.375vw 0;
}
.qrcode .qrcode-wrapper{
  padding: 0 1.5625vw;
}
.qrcode .qrcode-wrapper img{
  width: 9.375vw;
  height: 9.375vw;
  padding-bottom: 0.625vw;
}
.qrcode .qrcode-wrapper .qrcode-content{
  /* width: 9.583333vw; */
  height: 1.614583vw;
  font-size: 0.729167vw;
  font-family: OPPOSans-M, OPPOSans;
  font-weight: normal;
  color: rgba(30, 30, 30, 0.6);
  line-height: 1.614583vw;
}
</style>