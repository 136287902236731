export const REPAIR_STATUS = {
  0: '已申请',
  1: '待收货',
  3: '已收货',
  5: '待检测',
  7: '待维修',
  9: '维修中',
  11: '已关单',
  13: '已维修',
  15: '已寄回',
  17: '已结束'
}

export const EXPRESS_OPTIONS = [
  '顺丰速运',
  '圆通速递',
  '申通速递',
  '中通速递',
  '韵达速递',
  '百世快递',
  '邮政包裹',
  '京东快递',
  '极兔速递',
  'EMS',
  '其它'
]

export const PATTERN = {
  phone: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
  captcha: /^\d{4}$/,
  imei: /^\d{15}$/
}
