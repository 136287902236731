<template>
  <div class="cardlink">
    <div class="cardlink-title">近期文章</div>
    <a class="cardlink-item" href="news76.html">
      <div class="item-title" title="如何选购学生手机？请认准360OS奇少年学生手机这个品牌">如何选购学生手机？请认准360OS奇少年学生手机这个品牌</div>
      <div class="item-time">2022.08.15</div>
    </a>
    <a class="cardlink-item" href="news75.html">
      <div class="item-title" title="学生为什么要使用学生专用手机？360OS奇少年为青少年安全保驾护航">学生为什么要使用学生专用手机？360OS奇少年为青少年安全保驾护航</div>
      <div class="item-time">2022.08.15</div>
    </a>
    <a class="cardlink-item" href="news64.html">
      <div class="item-title" title="为什么要选360OS奇少年学生手机？它与一般手机的区别竟有这么大！">为什么要选360OS奇少年学生手机？它与一般手机的区别竟有这么大！</div>
      <div class="item-time">2022.08.10</div>
    </a>    
    <a class="cardlink-item" href="news73.html">
      <div class="item-title" title="360OS奇少年学生手机，最适合学生用的手机">360OS奇少年学生手机，最适合学生用的手机</div>
      <div class="item-time">2022.08.10</div>
    </a>
    <a class="cardlink-item" href="news72.html">
      <div class="item-title" title="孩子叛逆期，用360OS奇少年学生手机才是上策">孩子叛逆期，用360OS奇少年学生手机才是上策</div>
      <div class="item-time">2022.08.10</div>
    </a>
    <a class="cardlink-item" href="news71.html">
      <div class="item-title" title="管教无用时，不如买一部360OS奇少年学生手机吧">管教无用时，不如买一部360OS奇少年学生手机吧</div>
      <div class="item-time">2022.08.10</div>
    </a> 
    <a class="cardlink-item" href="news70.html">
      <div class="item-title" title="360OS奇少年学生手机精准解决家长育儿痛点">360OS奇少年学生手机精准解决家长育儿痛点</div>
      <div class="item-time">2022.07.27</div>
    </a>
    <a class="cardlink-item" href="news69.html">
      <div class="item-title" title="有没有适合学生用的儿童手机？选这款360OS奇少年学生手机就对了">有没有适合学生用的儿童手机？选这款360OS奇少年学生手机就对了</div>
      <div class="item-time">2022.07.27</div>
    </a>
    <a class="cardlink-item" href="news68.html">
      <div class="item-title" title="买学习产品要考虑哪些因素？360OS奇少年学生手机全部替你想好了">买学习产品要考虑哪些因素？360OS奇少年学生手机全部替你想好了</div>
      <div class="item-time">2022.07.27</div>
    </a>
    <a class="cardlink-item" href="news67.html">
      <div class="item-title" title="儿童手机需要有哪些功能？这款360OS奇少年学生手机全都满足！">儿童手机需要有哪些功能？这款360OS奇少年学生手机全都满足！</div>
      <div class="item-time">2022.07.27</div>
    </a>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
  .cardlink {
    box-sizing: border-box;
          /* px-to-viewport-ignore-next */
    padding: 40px 40px 52px 40px;
    background: #fff;

    .cardlink-title {
      color: #1f1f1f;
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 32px;
    }
    .cardlink-item {
      margin-bottom: 34px;
      text-decoration: none;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        cursor: pointer;
      }
      .item-title {
        padding:2px 0;
        color: #8B8B8B;
        font-size: 16px;
        margin-bottom: 9px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        &:hover{
          color: #000;
        }
      }
      .item-time {
        color: #b6b6b6;
        font-size: 14px;
      }
    }
  }
</style>