<template>
  <div class="footer-commons">
    <div class="contact-wrapper">
      <div class="contact-col address">
        <div class="footer-title">公司地址</div>
        <div class="footer-subtitle">
          <div class="left">北京：</div>
          <div class="right">北京市朝阳区酒仙桥路360大厦</div>
        </div>
        <div class="footer-subtitle">
          <div class="left">深圳：</div>
          <div class="right">广东省深圳市南山区学苑大道1001号南山智园A2栋10楼</div>
        </div>
        <div class="footer-subtitle">
          <div class="left">西安：</div>
          <div class="right">陕西省西安市高新区唐延路51号人寿壹中心B座7层713室</div>
        </div>
      </div>
      <div class="contact-col contact">
        <div class="footer-title">联系方式</div>
        <div class="footer-subtitle">市场合作：<span class="footer-website">marketing-os@360os.com</span></div>
        <div class="footer-subtitle">商务合作：<span class="footer-website">qishaonian@360os.com</span></div>
        <div class="footer-subtitle">客服电话：<span class="footer-website">400-832-3666</span></div>
      </div>
      <div class="contact-col protocol" v-if="showLinks">
        <div class="footer-title">友情链接</div>
        <div class="footer-subtitle">
          <a
            class="label-link"
            href="https://www.360.com"
            target="_blank"
            rel="nofollow"
          >奇虎360</a>
          <span>：</span>
          <a
            class="footer-website"
            href="https://www.360.com"
            target="_blank"
            rel="nofollow"
          >https://www.360.com</a>
        </div>
        <div class="footer-subtitle">
          <a
            class="label-link"
            href="https://www.360os.com"
            target="_blank"
          >360OS</a>
          <span>：</span>
          <a
            class="footer-website"
            href="https://www.360os.com"
            target="_blank"
          >https://www.360os.com</a>
        </div>
      </div>
    </div>
    <!-- <div class="report">
      <div class="report-title">全国涉黑涉恶违法犯罪线索举报平台：</div>
      <div class="report-website"><a
          href="https://www.12389.gov.cn/clue_evilNotice.action"
          target="_blank"
          rel="nofollow"
        >https://www.12389.gov.cn/clue_evilNotice.action</a></div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    showLinks: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.footer-commons {
  a {
    color: #41dea3;
    text-decoration: none;
  }

  .contact-wrapper {
    display: flex;
    justify-content: center;

    .contact-col {
      border-right: 1px solid rgba(255, 255, 255, 0.1);

      &:last-child {
        border-right: none;
      }
    }

    .address {
      width: 400px;
      padding-right: 60px;
    }
    .contact {
      padding: 0 60px;
    }
    .protocol {
      padding-left: 60px;
    }
  }
  .footer-title {
    height: 26px;
    font-size: 20px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #ffffff;
    line-height: 26px;
    padding-bottom: 32px;
  }
  .footer-subtitle {
    padding: 11px 0;
    height: 19px;
    font-size: 14px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #ffffff;
    line-height: 19px;
  }
  .footer-subtitle .label-link {
    color: #ffffff;
  }
  .footer-website {
    height: 19px;
    font-size: 14px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #41dea3;
    line-height: 19px;
  }
  .footer-subtitle .left {
    float: left;
  }
  .footer-subtitle .right {
    margin-left: 40px;
  }

  .report .report-title {
    width: 204px;
    height: 16px;
    font-size: 12px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #ffffff;
    line-height: 16px;
  }
  .report {
    padding-top: 20px;
    padding-bottom: 38px;
  }
  .report .report-website {
    width: 279px;
    height: 16px;
    font-size: 12px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #41dea3;
    line-height: 16px;
    padding-top: 6px;
  }
}
</style>