<template>
  <div class="imgs-upload-preview">
    <div class="imgs-upload">
      <div class="upload-box" v-for="(img, index) in imgs" :key="index">
        <div class="thumb-box" :style="{
          backgroundImage: 'url(' + img.url + ')'
        }">
          <div class="uploader-actions">
            <div class="action-btns">
              <span class="action-btn" title="预览" @click="onPreviewImg(img.url)">
                <i class="btn-icon el-icon-zoom-in"></i>
              </span>
              <span v-if="editable" 
                class="action-btn" 
                title="删除" 
                @click="onDelete(index)">
                <i class="btn-icon el-icon-delete"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <el-upload
        class="upload-box"
        v-show="imgs.length < limit && editable"
        action="/repair-api/upload/img"
        accept="image/*"
        multiple
        :show-file-list="false"
        auto-upload
        :data="{
          imeiOrSn:imeiOrSn
        }"
        :disabled='disabled'
        :on-success="onUploadSuccess"
        :on-complete="onComplete"
        :on-error="onUploadError"
        :before-upload="beforeUploadPicture"
        :on-progress="onUploading"
        :on-exceed="onUploadExceed"
      >
        <i class="el-icon-loading" v-if="uploading"></i>
        <i class="el-icon-plus" v-else></i>
      </el-upload>
    </div>
    <el-dialog :visible.sync="previewVisible" z-index="2000" @close="previewUrl = ''" width="51.5625vw">
      <img class="preview-img" :src="previewUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    limit: {
      type: Number,
      default: 10
    },
    editable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    imeiOrSn:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      uploading: false,
      previewVisible: false,
      previewUrl: '',
      imgs: []
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.imgs = val || []
      },
      deep: true      
    }
  },

  methods: {
    onPreviewImg (url) {
      this.previewUrl = url
      this.previewVisible = true
    },

    onDelete(index) {
      this.$emit('deleted', this.imgs[index])
      this.imgs.splice(index, 1)
      this.$emit('input', this.imgs)
    },

    onUploadSuccess(response) {
      if (response.code === 0) {
        if (this.imgs.length < this.limit) {
          let file = {
            id: undefined,
            url: response.data.url
          }
          this.$emit('added', file)
          this.imgs.push(file)
          this.$emit('input', this.imgs)
        }
      } else {
        this.$message.error('图片上传失败！')
        this.uploading = false
      }
      this.uploading = false
    },

    onUploading() {
      this.uploading = true
    },

    onUploadError() {
      this.$message.error('图片上传失败！')
      this.uploading = false
    },

    onUploadExceed() {
      this.$message.error('上传图片总数将超过'+ this.limit +'张')
    },

    beforeUploadPicture(file) {
      if (this.imgs.length >= this.limit) {
        return false
      }
      const isJPGPNG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/pjpeg"
      const isLt5M = file.size / 1024 / 1024 < 6;
      if (!isJPGPNG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 6MB!")
      }
      return isJPGPNG && isLt5M
    }
  }
}
</script>

<style lang="scss">
.imgs-upload {
  display: flex;
  flex-wrap: wrap;

  .upload-box {
    background-color: #fbfdff;
    border: 2px solid #E9EEEC;
    border-radius: 6px;
    box-sizing: border-box;
    width: 160px;
    height: 160px;
    line-height: 160px;
    box-sizing: border-box;
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 36px;

    .el-upload {
      width: 100%;
      height: 100%;
    }

    .el-icon-plus {
      font-size: 32px;
font-size: 1.666667vw;
    color: rgba(31,31,31,0.4);
    font-weight: bold;
    }

    .thumb-box {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;

      .action-btns {
        height: 24px;
        position: absolute;
        width: 100%;
        top: 50%;
        line-height: 24px;
        transform: translateY(-50%);
        font-size: 24px;
      }

      .btn-icon {
        color: #fff;
      }

      .action-btn {
        padding: 5px;
        display: inline-block;
        margin: 0 10px;
      }

      .uploader-actions {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;
        text-align: center;
      }

      &:hover {
        .uploader-actions {
          display: block;
        }
      }
    }
  }
}

.preview-img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
</style>
