import axios from 'axios'

export default (method, url, data) => {
  var body = data
  var params = ''
  let methodType = method.toLowerCase()
  let headers = {
    "x-token": localStorage.getItem('token')
  }
  if (['get', 'delete'].indexOf(method) > -1) {
    params = data
    body = ''
  }
  return axios({
    method: methodType,
    url: url,
    params: params,
    data: body,
    headers: headers,
    responseType: 'json',
    timeout: 60000
  }).then(resp => {
    var data = resp.data
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data)
      } catch (e) {
        return Promise.reject(e)
      }
    }
    if (data.code === 0) {
      return Promise.resolve(data)
    } else {
      // Message.error(data.message)
      return Promise.reject(resp)
    }
  }).catch(error => {
    let errorType = Object.prototype.toString.call(error).slice(7, -1)
    let data = errorType.indexOf('Error') > -1 ? error.response.data : error.data
    if (data) {
      if (data.returnUrl) {
        location.href = data.returnUrl
        return
      }
      console.log('data.code:', data.code)
      if (data.code === 8) {
        Vue.login()
      }
      return Promise.reject({
        code: data.code || -1,
        msg: data.msg || '网络错误，请稍后重试',
        data: data.data
      })
    }
    return Promise.reject({
      code: -1,
      msg: data.msg || '网络错误，请稍后重试'
    })
  })
}
