<template>
  <div class="login-dialog">
    <el-dialog
      :visible.sync="visible"
      :before-close="cancel"
      width="51.5625vw"
      center
      :close-on-click-modal="false"
    >
      <div class="main-title">{{title}}</div>
      <el-form
        :model="registerForm"
        :rules="rulesForm"
        ref="registerForm"
        label-width="5.625vw"
        class="register-form"
      >
        <el-form-item label="手机号码" prop="phone">
          <el-input class="phone" type="tel" v-model.trim="registerForm.phone" placeholder="请输入您的手机号码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="captcha">
          <el-input class="sendcode-input" v-model="registerForm.captcha" placeholder="请输入验证码"></el-input>
          <span
            class="send-code"
            type="text"
            @click="sendCode()"
            :disabled="disabled"
            v-if="disabled == false"
          >获取验证码</span>
          <span
            class="send-code countdown"
            type="text"
            @click="sendCode()"
            :disabled="disabled"
            v-if="disabled == true"
          >{{ btntxt }}</span>
        </el-form-item>
        <el-form-item class="submit" label-width="0">
          <el-button
            class="submit-button"
            size="medium"
            type="success"
            @click="submitForm()"
            :loading="loading"
          >确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { PATTERN } from "../../commons/utils/constants";
export default {
  data() {
    return {
      title: '登录',
      registerForm: {
        phone: '',
        captcha: '',
      },
      disabled: false,
      loading: false,
      time: 0,
      btntxt: "重新发送",
      rulesForm: {
        phone: [
          { required: true, message: '手机号码不能为空', trigger: ['blur', 'change'] },
          { pattern: PATTERN.phone, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '验证码不能为空', trigger: ['blur', 'change'] },
          { pattern: PATTERN.captcha, message: '请输入正确的验证码', trigger: ['blur', 'change'] }
        ],
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function
    }
  },
  methods: {
    cancel() {
     this.visible = false
    },

    sendCode() {
      // 验证手机号是否正确
      this.$refs.registerForm.validateField('phone', errMsg => {
        if (!errMsg) {
          this.$request('post', '/repair-api/sms/single', {
            phone: this.registerForm.phone
          }).then(() => {
            this.time = 60
            this.disabled = true;
            this.timer();
          }).catch(err => {
            this.$message({
              type: 'error',
              message: err.msg
            })
          })
        }
      })
    },

    timer() {
      if (this.time > 0) {
        this.time--
        this.btntxt = "剩余" + this.time + "秒"
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = "获取验证码"
        this.disabled = false
      }
    },

    submitForm() {
      // 验证验证码
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$request('post', '/repair-api/get-token', this.registerForm).then(resp => {
            localStorage.setItem('token', resp.data)
            this.$emit('login-success')
          }).catch(err => {
            this.$message({
              type: 'error',
              message: err.msg
            })
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.login-dialog {
  .el-dialog--center .el-dialog__body {
    padding: 40px 94px 50px;
  }
  .main-title {
    text-align: center;
    height: 42px;
    font-size: 32px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #191919;
    line-height: 42px;
    padding-bottom: 40px;
  }
  .send-code {
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    color: #31a9f8;
    padding-left: 18px;
    text-align: right;
    vertical-align: middle;
  }
  .send-code.countdown {
    color: #ccc;
  }
  .register-form {
    .el-input.phone-input {
      width: 682px;
    }
    .el-input.sendcode-input,
    .el-input.phone{
      width: 588px;
    }
    .el-input__inner:focus{
      border-color: #CDD5D2
    }

    .el-form-item  {
      margin-bottom: 40px;
    }
    .submit {
      text-align: center;
      .submit-button {
        width: 160px;
        height: 48px;
        line-height: 48px;
        background: #45c98e;
        border-color: #45c98e;
        font-family: OPPOSans-B, OPPOSans;
        font-size: 20px;
        border-radius: 4px;
        padding: 0;
      }
      .submit-button:focus,
      .submit-button:hover {
        background: #2BAD73;
        border-color: #2BAD73;
      }
    }
  }
}
</style>
