import '../commons/styles/footer.scss'
import './styles/news.css'
import CommonHeader from '../commons/components/CommonHeader'
import CommonFooter from '../commons/components/CommonFooter'
import RecentArticlesVue from './components/RecentArticles.vue'
import QrCodes from '../commons/components/QrCodes.vue'

Vue.config.productionTip = false
new Vue({
  render: h => h(CommonHeader, {
    props: {
      active: 'newsindex'
    }
  }),
}).$mount('#header')

new Vue({
  render: h => h(CommonFooter)
}).$mount('#footerCommon')

new Vue({
  render: h => h(RecentArticlesVue)
}).$mount('#cardLink')

new Vue({
  render: h => h(QrCodes)
}).$mount('#qrCodes')
