<template>
  <el-dialog
    class="queryimei-dialog"
    top="0.5vw"
    :visible.sync="visible"
    :before-close="cancel"
    width="51.5625vw"
    center
  >
    <div class="queryimei-title">如何查询设备IMEI/SN号</div>
    <div class="queryimei-subtitle">通过设置查询IMEI及SN</div>
    <div class="queryimei-subtitle-des">手机： 进入手机设置 > 关于手机 > IMEI，或打开手机设置，在搜索框输入IMEI。</div>
    <div class="queryimei-subtitle-des" style="margin-top:5px;">平板： 进入平板设置 > 关于平板电脑 > 版本信息 > 序列号，序列号即为平板SN号。</div>
    <div class="img-wrapper">
      <img class="queryimei-img1" src="../assets/imei-search.png" />
    </div>
    <div class="queryimei-subtitle">通过拨号查询IMEI</div>
    <div class="queryimei-subtitle-des">手机： 进入拨号功能界面，输入*#06#，界面会自动显示出设备的IMEI。</div>
    <div class="img-wrapper">
      <img class="queryimei-img1" src="../assets/mobilequery.png" />
    </div>
    <div class="queryimei-subtitle">通过包装盒查询IMEI及SN</div>
    <div class="queryimei-subtitle-des">您可以在设备原始包装盒的条形码下方查看IMEI/SN。</div>
    <div class="img-wrapper">
      <img class="queryimei-img2" src="../assets/boxquery.png" />
    </div>
    <span slot="footer" class="queryimei-footer">
      <el-button class="queryimei-btn" @click="cancel">知道了</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'queryimeidialog',
  data() {
    return {

    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('dialog-cancel');
    }
  }
}
</script>

<style lang="scss">
.queryimei-dialog {
  .queryimei-title {
    text-align: center;
    height: 42px;
    font-size: 32px;
    font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #191919;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .queryimei-subtitle {
    // text-align: center;
    height: 32px;
    font-size: 20px;
    font-family: OPPOSans-R, OPPOSans;
    font-weight: normal;
    color: #1060ab;
    line-height: 32px;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .queryimei-subtitle-des {
    // text-align: center;
    height: 32px;
    font-size: 20px;
    font-family: OPPOSans-R, OPPOSans;
    font-weight: normal;
    color: #1f1f1f;
    line-height: 32px;
  }
  .img-wrapper {
    text-align: center;
    margin-top: 10px;
  }
  .queryimei-img1 {
    width: 782px;
  }
  .queryimei-img2 {
    width: 782px;
  }
  .queryimei-btn {
    margin-bottom: 40px;
    width: 160px;
    padding: 0;
    height: 48px;
    background: #45c98e;
    border-color: #45c98e;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    font-size: 20px;
    &:hover{
     background: #2BAD73;
     border-color: #2BAD73;
    }
  }
}
</style>